import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../../styles/bootstrap-grid.css';
import '../../styles/styles.scss';
import { Helmet } from 'react-helmet';

const Layout = (props) => {
	return (
		<React.Fragment>
			<Helmet>
				<html lang="en" />
				<meta charSet="utf-8" />
				<title>PJ Portfolio</title>
				<meta name="description" content="PJ Web Developer Porfolio" />
			</Helmet>
			<Header />
			{props.children}
			<Footer />
		</React.Fragment>
	);
};

export default Layout;
