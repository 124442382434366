import React from 'react';

const Footer = () => {
	return (
		<footer>
			<div className="container">
				<p style={{ paddingTop: 20, paddingBottom: 20 }}>© Copyright 2020</p>
			</div>
		</footer>
	);
};

export default Footer;
